<template>
    <div class="ui-main">
        <a-spin :spinning="loading" size="large">
            <a-form ref="formRef" :model="modelRef" @finish="onSubmit">

                <a-form-item name="name" v-bind="formItemLayout" label="数据池名称" required :rules="[{required: true, message: '必填项不允许为空'}]" extra="自定义的，基本是影城的名称，需要是唯一的">
                    <a-input v-model:value="modelRef.name" />
                </a-form-item>

                <a-form-item name="username" v-bind="formItemLayout" label="用户名" required :rules="[{required: true, message: '必填项不允许为空'}]" extra="默认叫佳影默认:gvdba，辰星默认:upss， 如果有特殊的，阿杜或者老杨同步">
                    <a-input v-model:value="modelRef.username" />
                </a-form-item>

              <a-form-item name="ticketSystem" v-bind="formItemLayout" label="售票系统" required :rules="[{required: true, message: '必填项不允许为空'}]" extra="默认凤凰佳影">
                <a-select v-model:value="modelRef.ticketSystem" >
                  <a-select-option :value="1">凤凰佳影</a-select-option>
                  <a-select-option :value="2">辰星</a-select-option>
                </a-select>
              </a-form-item>

                <a-form-item name="password" v-bind="formItemLayout" label="密码" required :rules="[{required: true, message: '必填项不允许为空'}]" extra="默认叫佳影默认：manager，辰星默认：Ticketupss，如果有特殊的，阿杜或者老杨会同步">
                    <a-input
                            v-model:value="modelRef.password"
                    />
                </a-form-item>

                <a-form-item name="url" v-bind="formItemLayout" label="数据库地址" required :rules="[{required: true, message: '必填项不允许为空'}]" extra="阿杜提供的IP">
<!--                    <a-input v-model:value="modelRef.url" />-->
<!--                  <a-input v-model:value="modelRef.url" addon-before="jdbc:oracle:thin:@" addon-after=":1521:GVDB" />-->
                  <a-input v-model:value="modelRef.url"/>
                </a-form-item>
              <div style="color: red;">
                <p>佳影例子: jdbc:oracle:thin:@ip:1521:GVDB</p>
                <p>辰星例子: jdbc:mysql://ip:port/CMS?allowMultiQueries=true&autoReconnect=true&useUnicode=true&characterEncoding=utf8&useSSL=false&serverTimezone=Asia/Shanghai</p>
              </div>
              <a-form-item name="driver" v-bind="formItemLayout" label="数据库连接类型" required :rules="[{required: true, message: '必选项不允许为空'}]" extra="不用改">
                <a-input v-model:value="modelRef.driver"/>
              </a-form-item>
              <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                <a-button type="primary" html-type="submit" :loading="loading">提交</a-button>
              </a-form-item>
            </a-form>
        </a-spin>
    </div>
</template>

<script>
    // import * as systemApi from '@/service/modules/system';
    // import { addDataSource } from "@/";
    import { getCinemaList, getDataSourceList, addDataSourceList } from '@/service/modules/cinema.js';
    export default {
        props: {
            isEdit: {      // true :编辑  false: 新增
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loading: false,
                id: 0,
                modelRef: {
                  name: '',
                  username: '',
                  password: '',
                  url: '',
                  driver: 'oracle.jdbc.OracleDriver',
                  ticketSystem: 1,
                },
                form: null,
                isParent: 0,
                formItemLayout: {
                    labelCol: { span: 6 },
                    wrapperCol: { span: 14 },
                },
                menus: [],
                menusTwo: [],
				menusThird: []
            }
        },
        created() {
            // if (this.isEdit && this.$route.query.id) {
            //     this.id = this.$route.query.id;
            //     this.getData();
            // }
        },
        methods: {
            onSubmit() {
                // if (this.isEdit) {
                //   addDataSource(this.modelRef).then(res => {
                //         if (res.code == 200){
                //             this.$message.success('操作成功');
                //             this.$closePage(this.$router.currentRoute.value, '/systemRoot/dataSource/index');
                //         }
                //     })
                // } else {
              // this.modelRef.url ='jdbc:oracle:thin:@' + this.modelRef.url +':1521:GVDB'
              addDataSourceList(this.modelRef).then(res => {
                    if (res.code == 200){
                        this.$message.success('操作成功');
                        this.$closePage(this.$router.currentRoute.value, '/cinema/basis/dbsource/list');
                        // this.$closePage(this.$router.currentRoute.value, '/portPlatform/management/dataSource/view');
                    }
                }).catch(() => {})
            },
        }
    }
</script>

<style scoped>
    .ui-main {
        width: 700px;
        text-align: left;
    }
</style>
